<template lang="pug">
  .container-fluid
    .Carousel-clearfix.row(:class="{ 'Carousel--shops': isShops, 'Carousel--medium': isMedium, 'Carousel--featured': isFeatured }", v-if="src")
      im-loader(v-show="isLoading")
      .col-xs-12.col-sm-12.carouselStyles
        a.Carousel-title(:href="carousel.link", :alt="carousel.name", v-show="!isLoading", v-if="carousel.name && carousel.link") {{ carousel.name }} &nbsp;

      tiny-slider.Carousel-container(
        v-show="!isLoading",
        :autoInit="slider.autoInit",
        :responsive="slider.responsive",
        :mouseDrag="slider.mouseDrag",
        :nav="slider.nav",
        :navPosition="slider.navPosition",
        :swipeAngle="slider.swipeAngle",
        :speed="slider.speed",
        :controlsText="slider.controlsText"
        :slideBy="slider.slideBy"
        :onInit="slider.onInit"
        :center="slider.center"
        :gutter="slider.gutter"
      )
        .Carousel-slide(v-if="carousel.content == 'categories'", v-for="category in content", :key="category.id")
          im-card-category.Carousel-item(:category="category")
        .Carousel-slide(v-if="carousel.content == 'products'", v-for="product in content", :key="product.id_product")
          im-card-product.Carousel-item(:product="product")
        .Carousel-slide(v-if="carousel.content == 'shops'", v-for="shop in content", :key="shop.id_official_store")
          im-card-shop.Carousel-item(:shop="shop")
</template>

<script>
import TinySlider from '@/lib/tiny-slider'
import ImCardCategory from '@/components/CardCategory.vue'
import ImCardProduct from '@/components/CardProduct.vue'
import ImCardShop from '@/components/CardShop.vue'
import ImLoader from '@/components/Loader.vue'
import { http } from '@/services/http'

export default {
  name: 'ImCarousel',
  props: {
    src: { type: String, required: true, default: null },
    autoplay: { type: String, required: false, default: '0' },
    pagination: { type: [String, Number], required: false, default: '1' },
    desktopScreen: { type: String, required: false, default: 'full' }
  },
  components: {
    TinySlider,
    ImCardCategory,
    ImCardProduct,
    ImCardShop,
    ImLoader
  },
  data () {
    return {
      slider: {
        autoInit: false,
        responsive: {
          320: {
            autoWidth: true,
            items: 1.2
          },
          360: {
            items: 1.2
          },
          480: {
            items: 1.5
          },
          768: {
            items: 2.5
          },
          1025: {
            items: 4.5
          },
          1300: {
            items: 5,
            slideBy: 5
          },
          1500: {
            items: 5
          }
        },
        mouseDrag: true,
        onInit: true,
        nav: false,
        navPosition: 'bottom',
        swipeAngle: 15,
        speed: 1000,
        slideBy: 1,
        controlsText: [
          '<i class="glyphicon glyphicon-menu-left"></i>',
          '<i class="glyphicon glyphicon-menu-right"></i>'
        ]
      },
      carousel: {},
      content: [],
      isLoading: true,
      isShops: false,
      isFeatured: false,
      gutter: 0
    }
  },
  computed: {
    isAutoPlay () {
      if (parseInt(this.autoplay) === 1) {
        return true
      }

      return false
    },

    isMedium () {
      if (this.desktopScreen === 'medium') {
        return true
      }

      return false
    },

    isPagination () {
      if (parseInt(this.pagination) === 1) {
        return true
      }

      return false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    setConfigSlider () {
      if (this.carousel.style === 'Carousel--featured') {
        this.isFeatured = true
        /*
        Mobile
          */
        this.slider.responsive[320].nav = false
        this.slider.responsive[320].controls = true
        this.slider.responsive[320].slideBy = 1

        this.slider.responsive[480].items = 1.2
        this.slider.responsive[480].controls = true
        this.slider.responsive[480].slideBy = 1

        this.slider.responsive[768].items = 2.5
        this.slider.responsive[768].controls = true
        this.slider.responsive[768].slideBy = 2

        /*
        Desktop
          */
        this.slider.responsive[1025].items = 3
        this.slider.responsive[1025].slideBy = 3
        this.slider.responsive[1025].nav = false
        this.slider.responsive[1025].controls = true

        this.slider.responsive[1300].items = 3
        this.slider.responsive[1300].slideBy = 3
        this.slider.responsive[1300].nav = false
        this.slider.responsive[1300].controls = true

        this.slider.responsive[1500].items = 3
        this.slider.responsive[1500].slideBy = 3
        this.slider.responsive[1500].nav = false
        this.slider.responsive[1500].controls = true
      } else if (this.isShops === true) {
        /* Mobile */
        this.slider.responsive[320].nav = false
        this.slider.responsive[320].controls = true
        this.slider.responsive[320].items = 2
        this.slider.responsive[320].slideBy = 2

        this.slider.responsive[360].controls = true
        this.slider.responsive[360].items = 2.1
        this.slider.responsive[360].slideBy = 2

        this.slider.responsive[480].items = 2.5
        this.slider.responsive[480].controls = true
        this.slider.responsive[480].slideBy = 2

        this.slider.responsive[768].items = 3
        this.slider.responsive[768].controls = true
        this.slider.responsive[768].slideBy = 3
      }
    },
    getData () {
      this.isLoading = true

      http.get(this.src).then(res => {
        let data = res.data

        this.content = data.content
        this.carousel = data.carousel
        if (data.carousel.content === 'shops') {
          this.isShops = true
        }
        this.setConfigSlider()

        setTimeout(() => {
          this.slider.autoInit = true
          this.isLoading = false
        }, 1)
      })
    }
  }
}
</script>

<style lang="scss">
@import "~tiny-slider/src/tiny-slider";
$primary: #666666;
$purple: #583174;

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .glyphicon-menu-left{
    margin-left: -1.75rem;
  }
}

.Carousel-title {
  padding-top: 0;
  text-align: start;
  color: $primary;
  margin: 0px;
  z-index: 10;
  font-size: 1.8rem;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: $purple;
  text-underline-offset: 14px;
}

.tns-visually-hidden {
  position: relative;
  left: -10000em;
}

.carouselStyles {
  display: flex;
  z-index: 999;

  a {
    &:hover {
      text-decoration: inherit;
    }
  }
}

.tns-outer {
  position: relative;

  &:hover {
    .tns-controls {
      button {
        visibility: visible;
        opacity: 1;
        transition: opacity 0s linear;
        width: 4rem;
        height: 4rem;
        font-size: 2.5rem;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        color: $purple;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
        text-align: center;
        align-items: center;
        position: absolute;
        font-weight: 0;
        z-index: 1;
        top: 45%;
        box-sizing: border-box;
        text-decoration: none;
        appearance: none;
        border: none;
        cursor: pointer;
        outline: none;
        font-weight: normal;

        &[data-controls="prev"] {
          left: -0.5rem;
          padding-right: 0.7rem;
        }
        &[data-controls="next"] {
          right: -1rem;
          padding-left: 0.7rem;
        }

        &:hover {
          box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.2),
            0 1px 3px 0 rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
        }
      }
    }
  }

  .tns-controls {
    button {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s 0s, opacity 0s linear;
      width: 4rem;
      height: 4rem;
      font-size: 2.5rem;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
      color: $purple;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
      text-align: center;
      align-items: center;
      position: absolute;
      font-weight: 0;
      z-index: 1;
      top: 45%;
      box-sizing: border-box;
      text-decoration: none;
      appearance: none;
      border: none;
      cursor: pointer;
      outline: none;
      font-weight: normal;
      @media all and (min-width: 1024px) {
        visibility: hidden;
        opacity: 0;
      }

      &[data-controls="prev"] {
        left: -0.5rem;
        padding-right: 1rem;
      }

      &[data-controls="next"] {
        right: -1rem;
        padding-left: 1rem;
      }
    }
  }

  .tns-nav {
    text-align: center;

    button {
      border: 0px;
      width: 0px !important;
      height: 12px;
      border-radius: 50%;
      margin-right: 1rem;

      &:focus {
        outline: none;
      }
    }

    &-active {
      background-color: $primary;
      outline: none;
    }
  }
}

@media all and (min-width: 360px) {
  .Carousel-title {
    font-size: 1.2rem;
  }
}

@media all and (min-width: 320px) {
  .Carousel-title {
    font-size: 1.2rem;
  }
}

@media all and (min-width: 1025px) {
  .Carousel-title {
    color: $primary;
    margin: 0px;
    z-index: 10;
    font-size: 1.8rem;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: $purple;
    text-underline-offset: 14px;
  }
}
</style>
<style lang="scss" scoped>
.container-fluid {
  padding: 0rem 1rem 1rem 1rem;
}

.tns-ovh {
  margin-top: -10px;
}
</style>
