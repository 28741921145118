<template lang="pug">
  .Card.ajax_block_product(aria-hidden='false', :data-id-product='product.id', :data-id-product-attribute='product.id_product_attribute')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .Card-header
        .Card-header-logo_supplier
          figure
            img.replace-2x.img-responsive(:src='product.supplier.image', :title='product.supplier.name', :alt='product.supplier.name')

        .Card-header-delivery(v-if="product.marks", v-for="mark in product.marks", :key="mark.id")
          figure
            img(:src='mark.image', :title='mark.name', :alt='mark.name')

      .Card-body
        .Card-image
          figure
            img.replace-2x.img-responsive(:src='product.cover', :alt='product.name', :title='product.name', itemprop='image')

        h1.Card-name(itemprop='name') {{ product.name }}

        .Card-payments
          .Card-reduction(v-if="product.price.reduction")
            | Antes&nbsp;
            span.Card-old-price $ {{ product.price.list | numberFormat }}
          .Card-reduction(v-else)

          h3.Card-price $ {{ product.price.final | numberFormat }} &nbsp;

          .Card-percent(v-if="product.price.reduction")
            span.Card-percent-reduction {{ discount }} OFF
          .Card-percent(v-else)

          .Card-quota(v-if="product.price.quota_without_interest")
            small {{ product.price.quota_without_interest }} &nbsp;
            | cuotas sin interés de $ {{ costQuota | numberFormat }}
          .Card-quota(v-else)

          .Card-quota(v-if="product.price.quota")
            | ó &nbsp;
            small {{ product.price.quota }} &nbsp;
            | cuotas fijas
          .Card-quota(v-else)

          h4.Card-points(v-if="product.points")
            | ó {{ product.points[2].amount | numberFormat('0,000') }} puntos
            | &nbsp;+ $ {{ product.points[2].cash | numberFormat }}
            small &nbsp;
          h4.Card-points(v-else)

      .Card-footer
        .Card-best-price
          .Card-best-price-percentage
            span {{ product.price.best_percentage }}
            .percent %
            p DE AHORRO<br>ADICIONAL
              small (4)
          .Card-best-price-text
            p SI DEPOSITÁS<br>TU SUELDO<br>EN ICBC.
          .Card-best-price-amount
            span ${{ product.price.best | numberFormat }}

      a.Card-link(:href='product.link', :title='product.name', itemprop='url')
</template>

<script>
import { numberFormat } from '@/filters/number-format'
import { http } from '@/services/http'

export default {
  name: 'CardFeatured',
  props: {
    src: { type: String, required: true, default: null }
  },
  mounted () {
    this.getData()
  },
  methods: {
    parseProduct () {
      if (typeof this.product === 'string') {
        this.product = JSON.parse(this.product)
      }
    },
    data () {
      return {
        carousel: {},

        isLoading: true,
        isShops: false
      }
    },
    getData () {
      this.isLoading = true

      http.get(this.src).then(res => {
        let data = res.data

        this.product = data.content

        this.isLoading = false
      })
    }
  },
  computed: {
    costQuota () {
      let price = parseFloat(this.product.price.final)
      let quotas = parseInt(this.product.price.quota_without_interest)

      let result = price / quotas

      return result
    },
    discount () {
      let reduction = this.product.price.reduction
      let result = parseInt(Math.round(reduction * 100))
      return `${result}%`
    }
  },
  filters: {
    numberFormat
  },
  beforeMount () {
    this.parseProduct()
  },
  beforeUpdate () {
    this.parseProduct()
  }
}
</script>

<style lang="scss" scoped>
$color-black: black;
$color-red: #c4161c;
$color-grey: #464646;
$color-brown: #635843;
$width-card: 75vw;
$root-font-size: 14px;

.Card-header-logo_supplier figure img {
  height: 2rem !important;
}

.Card {
  font-family: Gustan, sans-serif;
  font-size: $root-font-size;
  padding: 0.5em;
  width: 100%;
  height: auto;

  &-best-price {
    align-items: center;
    height: 6%;
    display: flex;
    background: $color-brown;
    color: white;
    font-size: 1.15em;
    font-weight: 500;
    justify-content: space-between;
    min-height: 3em;
    // padding: .4em .2em;

    &-amount {
      width: 70%;
      text-align: center;
    }

    &-text {
      border-left: 2px dotted white;
      border-right: 2px dotted white;
      display: flex;
      min-height: 3.55em;
      width: 42%;
      align-items: center;
      justify-content: center;

      p {
        align-self: left;
        font-size: 0.5em;
        line-height: 1.3;
        margin: 0;
        padding-left: 0.3em;
        padding-right: 0.3em;
        line-height: 1em;
        text-align: left;
      }
    }

    &-percentage {
      text-align: left;
      display: inline-block;
      width: auto;
      padding: 0% 1%;

      span {
        font-size: 1.3em;
        font-weight: 600;
        line-height: 0.9em;
      }

      p {
        font-size: 0.5em;
        margin: 0;
        line-height: 1em;
      }

      .percent {
        font-size: 1em;
        font-weight: 600;
        display: inline-block;
        width: auto;
        margin: auto;
        line-height: 0.6em;
      }
    }
  }

  &-container {
    background: white;
    padding: 0.35em;
    padding-top: 0;
    position: relative;
    // border: 1px solid #d6d4d4;
    border: 1px solid transparent;
    // box-shadow: 1px 1px 2px #dddddd;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
    // min-height:  78vh;
    max-width: $width-card;
    margin: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:hover {
      border: 1px solid #d1181e;
      cursor: pointer;
    }
  }

  &-header {
    display: flex;
    align-items: center;

    &-logo_supplier,
    &-delivery {
      flex: auto;
    }

    &-logo_supplier {
      img {
        max-height: 2.3em;
      }
    }

    &-delivery figure {
      max-width: 3.8em;

      img {
        max-width: 3.12em;
      }
    }

    &-delivery {
      text-align: center;
    }
  }

  &-image figure {
    max-width: $width-card;

    img {
      width: 70%;
      margin: auto;
    }
  }

  &-link {
    position: absolute;
    top: 0;
    top: 10%;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  &-name {
    color: $color-black;
    font-size: 0.9em;
    // font-weight: 500;
    // font-weight: normal;
    line-height: 1.1;
    margin: 0.35em 0;
    height: 2em;
    // padding: 10px 0;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: capitalize;
    margin-left: 0.28em;
  }

  &-old-price {
    color: $color-grey;
    text-decoration: line-through;
    font-weight: 600;
  }

  &-payments {
    text-align: left;
    margin-left: 0.28em;
    font-weight: 500;
  }

  &-percent {
    display: inline-block;
  }

  &-percent-reduction {
    color: $color-red;
    font-size: 0.7em;
    font-weight: 600;
    margin-left: -0.5em;
  }

  &-points {
    font-size: 0.8em;
    margin: 1.1em 0;
    min-height: 1.15em;
    color: black;
    font-weight: 600;

    small {
      font-size: 0.9em;
      color: black;
      font-weight: 600;
    }
  }

  &-price {
    font-size: 1.2em;
    margin: 0;
    font-weight: 600;
    color: $color-black;
    display: inline-block;
  }

  &-quota {
    font-weight: 600;
    font-size: 0.7em;
    margin-left: -1px !important;
    min-height: 1.34em;

    small {
      font-size: 1em;
    }
  }

  &-reduction {
    font-size: 0.8em;
    font-weight: 600;
    padding: 0;
    height: 1.2em;
    margin-bottom: 0.3em;
  }
}

// @media (min-width: 1440px) and (min-width: 900px) {
//   .Card {
//     &-best_price{
//       &-amount{
//         width: 70%;
//         text-align: center;
//       }
//     }
//   }
// }

@media all and (min-width: 360px) {
  $root-font-size: 16px;

  .Card {
    font-size: $root-font-size;

    /* &-footer {
        font-size: $root-font-size;
      } */

    /* &-name {
        font-size: 1.2em;
      } */

    /* &-payments {
        font-size: $root-font-size;
      } */
  }
}

/* @media all and (min-width: 410px) {

  } */

@media all and (min-width: 768px) {
  /* .Card {
      &-container {
        // min-height:  573px;
        // min-width: 282px;
      }
    } */
}

@media all and (min-width: 991px) {
  .Card {
    &-best-price {
      &-amount {
        width: 75%;
        text-align: center;
      }

      &-text {
        width: 48%;
        /*min-width: 27%;*/
        p {
          -ms-transform: translateY(50%);
          font-size: 0.5em;
        }
      }

      &-percentage {
        /*width: 28%;*/
        min-width: 27%;
        p {
          padding-top: 4px;
          font-size: 0.5em;
          &-percent {
            font-size: 0.8em;
          }
        }
      }
    }

    &-image figure {
      img {
        width: 100%;
      }
    }

    // &-container {}

    &-percent-reduction {
      font-size: 0.8em;
    }

    &-points {
      font-size: 0.8em;
      small {
        font-size: 0.8em;
        color: black;
        font-weight: 600;
      }
    }

    &-price {
      font-size: 1.4em;
    }
  }
}
</style>
