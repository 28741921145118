<template lang="pug">
.ProductList-grid
  article(v-for="product in products", :key="product.id_product")
    card-product(:product="product")
</template>

<script>
// import prestashop from 'prestashop'
import CardProduct from '@/components/CardProduct.vue'

export default {
  name: 'ProductList',
  props: {
    content: {
      type: [String, Object],
      required: true
    }
  },
  components: {
    CardProduct
  },
  data () {
    return {
      products: []
    }
  },
  methods: {
    parseData () {
      if (typeof this.content === 'string') {
        this.products = JSON.parse(this.content)
        // eslint-disable-next-line
        console.log(this.product)
      }
    },
    renderHandler (data) {
      if (typeof data === 'string') {
        this.products = JSON.parse(data)
      }
    }
  },
  created () {
    this.parseData()
  },
  mounted () {
    const self = this
    document.addEventListener(
      'updateProduct',
      event => {
        const data = event.detail
        self.renderHandler(data)
      },
      false
    )
  }
}

</script>

<style lang="scss" scoped>
.ProductList {
  &-grid {
    display: grid;
    width: 100%;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

</style>
