<template lang="pug">
  div(:class="'Banner-Container'" v-if="banner != null")
      a(:href="[banner.link]" :target="[banner.target]")
        img(loading="lazy" :src="`/img/customhome/banner_${banner.item_id}.jpg`")
</template>

<script>
export default {
  name: 'Banner',
  props: {
    data: { type: Object }
  },
  data () {
    return {
      banner: null
    }
  },
  methods: {
    parseData () {
      if (typeof this.data === 'string') {
        this.banner = JSON.parse(this.data)
      }
    }
  },
  mounted () {
    this.parseData()
  }
}
</script>
<style lang="scss">
.Banner-Container
{
  margin-bottom: 20px;
  @media (min-width: 1024px) {
    margin-bottom: 0px;
    position: relative;
    height: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    text-align: center;
  }
}
</style>
