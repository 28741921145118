<template lang="pug">
.DropdownSlot(:class="{ DropdownSlotActive: isActive }")
  .DrodownBlack(v-on:click="active")
  .DropdownSlot-icon(v-on:click="active")
    slot(name='icon')
  .DropdownSlot-content
    slot(name='content')

</template>
<script>

export default {
  name: 'DropdownSlot',
  data () {
    return {
      isActive: false
    }
  },
  methods: {
    active () {
      if (screen.width < 765) {
        this.isActive = !this.isActive
      }
    }
  }
}
</script>
<style lang="scss">
.DropdownSlot {
  &-icon {
    i {
      font-size: 1.5rem;
      @media all and (min-width: 1024px) {
        font-size: 2rem;
        padding: 0;
        margin-bottom: -0.2rem;
      }
    }
    // color: $primary;
    cursor: pointer;
    position: relative;
    z-index: 30;
    text-transform: uppercase;
    color: #333;
    margin-bottom: 8px;
    @media all and (min-width: 1024px) {
      padding-right: 30px;
      background-color: transparent;
    }
  }
  &-content {
    display: none;
    opacity: 0;
    position: absolute;
    z-index: 20;
    width: 160px;
    .list-group {
      width: 100%;
      @media all and (min-width: 1024px) {
        width: auto;
      }
    }
    @media all and (min-width: 1024px) {
      background-color: #ffffff;
      padding: 1.4rem;
      border-radius: 4px;
      color: rgb(68, 68, 68);
      margin-top: -1px;
      font-size: 1rem;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      a {
        background-color: white !important;
        color: rgb(68, 68, 68);
        line-height: 34px;
        font-weight: 400;
      }
    }
  }
  &:hover{
    @media all and (min-width: 1024px) {
      .DropdownSlot-content{
        display: block;
        opacity: 1;
        .list-group {
          display: flex !important;
        }
      }
    }
  }
}

</style>
