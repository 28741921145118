<template lang="pug">
.Loader
  spinner

</template>

<script>
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Loader',
  components: {
    Spinner
  }
}

</script>

<style lang="scss" scoped>
.Loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
</style>
