<template lang="pug">
  .Card(aria-hidden='false')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .CardHeader
        figure.CardHeader-image(:class='categoryIcon')

      .CardBody
        p.CardBody-title(itemprop='name') {{ category.name }}

      a.Card-link(:href='category.link', :title='category.name', itemprop='url')
</template>

<script>
export default {
  name: 'CardCategory',
  props: {
    category: { type: Object, required: true }
  },
  computed: {
    categoryIcon () {
      let icon = ''
      let category = this.category.name.toLowerCase()

      let icons = {
        ropa: 'ropa',
        moda: 'ropa',

        bebes: 'bebes',
        niñ: 'bebes',
        juguetes: 'bebes',

        electro: 'electrodomesticos',

        tecno: 'tecnologia',

        hogar: 'hogar',
        casa: 'hogar',
        deco: 'hogar',

        mascotas: 'mascotas',

        viajes: 'viajes',
        turismo: 'viajes',
        equipaje: 'viajes'
      }

      for (let key in icons) {
        if (category.indexOf(key) >= 0) {
          icon = `supervielle-${icons[key]}`
          break
        }
      }

      return icon
    }
  }
}
</script>

<style lang="scss" scoped>
  $primary: #ed2224;
  $secondary: #444445;
  $color-grey: #6a6b6d;
  $color-white: #fff;
  $background-gradient: linear-gradient(90deg,rgba(237,34,36,1)  0,rgba(130,22,67,1) 100%);
  $background-gradient-invested: linear-gradient(90deg, rgba(130,22,67,1) 0, rgba(237,34,36,1)100%);
  $width-card: 80vw;
  $root-font-size: 1rem;

  .Card {
    padding: 1em;
    font-family: Whitney, sans-serif;
    width: 100%;
    height: auto;

    &-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 1rem;
      background: transparent;
      position: relative;
      max-width: $width-card;
      margin: auto;
      box-shadow: 0 0 6px 0 rgba(0,0,0,.2);
      transition: .3s;

      &:hover {
        background: $background-gradient;
        cursor: pointer;

        .CardHeader {
          border:none;
          background-color: transparent;

          &-image {
            background: $color-white;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .CardBody {
          border: none;
          background-color: transparent;

          &-title {
            color:$color-white;
            }
        }
      }
    }

    &Header {
      background-color: $color-white;
      // border: 1px solid #cdc3bc;
      border-radius: 1rem 1rem 0 0;
      width: 100%;
      height: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &-image {
        font-size: 10rem;
        background: linear-gradient(130deg,rgba(237,34,36,1)  0,rgba(130,22,67,1) 75%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: .3s;
      }
    }

    &Body {
      border: 1px solid transparent;
      visibility: visible;
      opacity: 1;
      transition: opacity 1s linear;
      background-color: #cdc3bc;
      height: 5rem;
      text-align: center;
      border-radius: .5rem .5rem 1rem 1rem;
      display: flex;
      justify-content:center;
      align-items: center;
      margin-top: -4px;

      &-title {
        display:block;
        margin:0px;
        width: 10rem;
        font-size:  $root-font-size * 1.5;
        color: #434344;
        text-transform: uppercase;
        transition: .3s;
      }
    }

    &-link {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  /* @media all and (min-width: 360px) {
    .Card {
    }
  } */

  /* @media all and (min-width: 410px) {
    .Card {
    }
  } */

  /* @media all and (min-width: 768px) {

  } */

  /* @media all and (min-width: 991px) {
    .Card {
      &Header {
        width: 100%;
        height: 11rem;
      }
    }
  } */

</style>
