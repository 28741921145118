<template lang="pug">
  .Card.ajax_block_product(aria-hidden='false', :data-id-product='product.id', :data-id-product-attribute='product.id_product_attribute')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      a.CardProduct-container(:href='product.link', :title='product.name', itemprop='url')
        .CardBody
          div.CardHeader
            .CardBody-percent(v-if="product.price.reduction > 0" )
              p.CardBody-percent-reduction -{{ discount }} OFF
            .CardBody-percent(v-else style="display: none;")

            .CardBody-supplier
              figure
                img.replace-2x(loading="lazy" :src='product.supplier.image', :title='product.supplier.name', :alt='product.supplier.name')
          .CardBody-image
            figure
              img.replace-2x.img-responsive(loading="lazy" :src='product.cover', :alt='product.name', :title='product.name', itemprop='image')

          .CardBody-payments
            h2.CardBody-name(itemprop='name') {{ product.name.toLowerCase()}}
              .CardBody-delivery
                figure(v-if="product.marks", v-for="mark in product.marks", :key="mark.id")
                  img(loading="lazy" :src='mark.image', :title='mark.name', :alt='mark.name')

            div.AllPayments
              .CardBody-old-price(v-if="product.price.reduction > 0") ${{ product.price.list | numberFormat }}
              .CardBody-old-price(v-else style="display: none;") $0
              .CardBody-old-price(v-else)
              h4.CardBody-price <span>$</span>{{ product.price.final | numberFormat }} &nbsp;
            .CardFooter-quota(v-if="product.price.quota_without_interest")
              p.CardFooter-tax(v-if="product.price.quota_without_interest == 1") {{ product.price.quota_without_interest }} cuota sin interes de ${{ costQuota | numberFormat }}
              p.CardFooter-tax(v-else) {{ product.price.quota_without_interest }} cuotas sin intereses de ${{ costQuota | numberFormat }}
              p.CardFooter-tax.tax-second(v-if="product.price.quota_without_interest < product.price.quota && product.price.quota == 31" ) o 30 cuotas fijas
              p.CardFooter-tax.tax-second(v-else-if="product.price.quota_without_interest < product.price.quota && product.price.quota == 1" ) o {{product.price.quota}} cuota fija
              p.CardFooter-tax.tax-second(v-else-if="product.price.quota_without_interest < product.price.quota" ) o {{product.price.quota}} cuotas fijas
              p.CardFooter-tax.tax-second(v-else style="display: none;")
            .CardButton
              a.btn-buy.btn.btn-default(:href='product.link', :title='product.name', itemprop='url') Comprar
</template>

<script>
/* eslint-disable */

import { numberFormat } from "@/filters/number-format";

export default {
  name: "CardProduct",
  props: {
    product: { type: [Object, String], required: true }
  },
  methods: {
    parseProduct() {
      if (typeof this.product === "string") {
        this.product = JSON.parse(this.product);
        // eslint-disable-next-line
        // console.log(this.product)
      }
    }
  },
  computed: {
    costQuota() {
      let price = parseFloat(this.product.price.final);
      let quotas = parseInt(this.product.price.quota_without_interest);

      let result = price / quotas;

      return result;
    },
    discount() {
      let reduction = this.product.price.reduction;
      let result = parseInt(Math.round(reduction * 100));
      return `${result}%`;
    }
  },
  filters: {
    numberFormat
  },
  beforeMount() {
    this.parseProduct();
  },
  beforeUpdate() {
    this.parseProduct();
  }
};
</script>

<style lang="scss" scoped>
$primary: #333333;
$secondary: #444445;
$color-grey: #6a6b6d;
$color-white: #fff;
$payment-background: #f6f7f9 0% 0% no-repeat padding-box;
$background-gradient-invested: #B99DCE 0% 0% no-repeat padding-box;
$width-card: 80vw;
$root-font-size: 1rem;
$font-family: "Open Sans", sans-serif;


.CardHeader{
  min-height: 26px !important;
}

.CardBody-delivery{
  top: -5.5rem !important ;
  right: 2%;
  @media (max-width: 576px) {
    top: -5.3rem !important;
  }
}

.btn-buy {
  text-transform: uppercase;
}

.Carousel--featured  {
  .btn-buy {
    background-color: #B99DCE!important;
  }

  .CardBody-percent {
    background-color: #583174!important;
  }
}


.CardBody-supplier figure img {
  object-fit: contain !important;
}

/* .CardBody {
  margin-top: -14px;
} */

.Card {
  font-family: $font-family;
  font-size: $root-font-size * 1.5;
  padding: 0.5em;
  width: 100%;
  height: auto;
  position: relative;

  &-container {
    max-width: $width-card;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &Header {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    justify-content: flex-end;
  }

  &Body {
    padding-top: 1rem;
    position: relative;
    border-radius: 1rem 1rem 0 0;
    background: #fff;

    &-delivery {
      padding: 0rem;
      top: -2.3rem;
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: row-reverse;
      width: 100%;

      figure {
        background-color: #fff;
        box-shadow: 2px 1px 8px #cfcecea8;
        margin-left: 0.8rem;
        padding: 0.4rem;
        max-width: 44px;
        border-radius: 0.3rem;

        img {
          max-width: 100%;
        }
      }
    }

    &-image {
      height: 182px;
      figure {
        max-width: $width-card;

        img {
          width: 75%;
          margin: auto;
          position: relative;
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
        }
      }
    }

    &-name {
      color: $secondary;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      text-align: center;
      font-size: 1.6em;
      line-height: 1.2;
      height: 2.1rem;
      overflow: hidden;
      text-transform: capitalize !important;
      font-weight: 300;
      font-family: $font-family;
    }

    &-supplier {
      figure {
        height: 30px;
        width: 90px;
        margin-left: 40px;

        @media (min-width: 1024px) and (max-width: 1200px) {
          margin-left: 15px !important;
        }

        img {
          object-fit: cover;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }

    &-old-price {
      font-size: 0.8em;
      padding: 0;
      height: 1.2em;
      margin-bottom: 0.3em;
      color: $color-grey;
      text-decoration: line-through;
      font-weight: 500;
    }

    &-payments {
      background: $payment-background;
      height: 190px;
      text-align: left;
      height: auto;
      position: relative;

      .AllPayments {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
      }

      a.btn {
        margin-top: 5%;
      }

      a.btn:hover {
        color: #fff;
      }
    }

    &-percent {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.6rem;
      width: 40%;
      background: $background-gradient-invested;
      opacity: 1;

      &-reduction {
        font-size: 0.9em;
        font-weight: bold;
        color: #fff;
      }
    }

    &-price {
      font-size: 0.9em;
      margin: 0;
      font-weight: bold;
      color: $primary;
      display: inline-block;

      span {
        font-size: 2rem;
      }
    }
  }

  &Button {
    display: flex;
    justify-content: center;

    a {
      width: 190px;
    }
  }

  &Footer {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    color: white;
    text-align: left;
    min-height: 3em;
    border-radius: 0.5rem;
    position: relative;
    top: -4px;

    &-percentage {
      text-align: left;
      display: inline-block;
      width: auto;
      padding: 0% 1%;

      span {
        font-size: 1.3em;
        font-weight: bold;
        line-height: 0.9em;
      }

      p {
        font-size: 0.5em;
        margin: 0;
        line-height: 1em;
      }

      .percent {
        font-size: 1em;
        font-weight: bold;
        display: inline-block;
        width: auto;
        margin: auto;
        line-height: 0.6em;
      }
    }

    &-quota {
      height: auto;
      padding: 1rem 0rem 1rem 0rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p.CardFooter-tax {
        text-align: center;
        font-size: 1.3rem;
      }

      p.CardFooter-tax.tax-second {
        margin-top: 1%;
        text-align: center;
      }
    }

    &-tax {
      font-size: 1.2rem;
      margin-bottom: 0px;
      font-weight: 500;
      line-height: 1.3rem;

      strong {
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.3rem;
      }
      span {
        font-size: 1.3rem;
      }
    }

    &-cart {
      position: absolute;
      top: -1.8rem;
      right: 0rem;

      figure {
        background-color: #fff;
        box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.2);
        margin-right: 1rem;
        padding: 0.4rem;
        max-width: 35px;
        border-radius: 0.3rem;

        img {
          max-width: 100%;
        }
      }
    }
  }
}

@media all and (min-width: 320px) {
  .Card {
    height: 364px;

    &Body {
      &-image {
        height: 110px;
      }

      &-payments {
        height: 190px;
      }

      &-payments .AllPayments {
        justify-content: space-around;
      }

      &-percent {
        width: 32%;
        &-reduction {
          margin: auto;
          font-size: 0.65em;
        }
      }

      &-price {
        font-size: 0.7em;
        span {
          font-size: 1.4rem;
        }
      }

      &-old-price {
        font-size: 0.7em;
      }

      &-name {
        margin-top: 10%;
        height: 4.1rem;
        font-size: 0.8em;
      }
    }

    &Button a {
      width: 100px;
    }
  }
}

@media all and (min-width: 360px) {
  $root-font-size: 16px;

  .Card {
    font-size: $root-font-size;
    height: 420px;

    &Body {
      &-image {
        height: 128px;
      }

      &-payments {
        height: 190;
      }

      &-payments .AllPayments {
        justify-content: space-around;
      }

      &-delivery figure {
        max-width: 49px;
      }

      &-percent {
        width: 32%;

        &-reduction {
          margin: auto;
          font-size: 0.65em;
        }
      }

      &-price span {
        font-size: 1.2rem;
      }

      &-name {
        margin-top: 10%;
        height: 4.1rem;
        font-size: 0.8em;
      }
    }

    &Button a {
      width: 120px;
    }

    &Footer {
      &-quota {
        padding: 1rem 0rem 1rem 0rem;
      }

      &-tax {
        font-size: 1.3rem;
        line-height: 1.5rem;

        strong {
          font-size: 1.3rem;
          line-height: 1.5rem;
        }

        span {
          font-size: 1.4rem;
        }
      }

      &-cart {
        figure {
          margin-right: 1.2rem;
        }
      }
    }
  }
}

@media all and (min-width: 411px) {
  .Card {
    font-size: $root-font-size * 1.8;
    height: 435px;

    &Body {
      &-image {
        height: 155px;
      }

      &-percent {
        &-reduction {
          margin: auto;
          font-size: 0.65em;
        }
      }

      &-delivery figure {
        max-width: 49px;
      }

      &-payments {
        height: 190px;

        .AllPayments {
          justify-content: space-around;
        }
      }

      &-price {
        font-size: 0.7em;
        span {
          font-size: 1.6rem;
        }
      }

      &-old-price {
        font-size: 0.7em;
      }

      &-name {
        margin-top: 2rem;
        height: 4.1rem;
        font-size: 0.7em;
      }
    }

    &Button a {
      width: 120px;
    }

    &Footer {
      &-cart {
        figure {
          margin-right: 1.3rem;
          max-width: 38px;
        }
      }

      &-tax {
        font-size: 1.5rem;

        strong {
          font-size: 1.5rem;
        }

        span {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media all and (min-width: 768px) {
  .Card {
    height: 372px;

    &Body {
      &-image {
        height: 108px;
      }
      &-percent {
        width: 42%;

        &-reduction {
          margin: auto;
          font-size: 0.6em;
        }
      }

      &-name {
        font-size: 0.6em;
      }

      &-payments {
        height: 190px;

        .AllPayments {
          justify-content: space-around;
        }
      }

      &-price {
        font-size: 0.65em;
        span {
          font-size: 1.4rem;
        }
      }

      &-old-price {
        font-size: 0.5em;
      }
    }

    &Button a {
      width: 100px;
    }

    &Footer {
      &-quota {
        figure {
          max-width: 8rem;

          img {
            width: 100%;
          }
        }
      }
      &-tax {
        font-size: 1.2rem;

        strong {
          font-size: 1.2rem;
        }

        span {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media all and (min-width: 1024px) {
  .Card {
    height: 422px;

    &-container {
      &:hover {
        cursor: pointer;

        .CardBody {
          border-radius: 1rem 1rem 0 0;
          border-bottom: none;
        }

        .CardFooter {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.4s linear;
        }
      }
    }

    &Body {
      border-radius: 1rem;

      &-image {
        height: 145px;
      }

      &-name {
        font-size: 1.6rem;
        height: 4.1rem;
      }

      &-old-price {
        font-size: 1.3rem;
      }

      &-payments {
        height: 190px;
      }

      &-price {
        font-size: 0.7em;
        font-weight: bold;
      }

      &-percent {
        &-reduction {
          margin: auto;
          font-size: 0.8em;
        }
      }
    }

    &Footer {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0s, opacity 0s linear;

      &-quota {
        figure {
          max-width: 9rem;
          padding: 0.5rem 1.2rem 0.5rem 0rem;
        }
      }

      &-tax {
        font-size: 1.3rem;

        strong {
          font-size: 1.3rem;
        }

        span {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media all and (min-width: 1300px) {
  .Card {
    height: 460px;

    &Body {
      &-image {
        height: 183px;
      }

      &-percent {
        width: 40%;
      }
    }
  }
}

@media all and (min-width: 1600px) {
  .Card {
    height: 455px;

    &Footer {
      &-quota {
        figure {
          max-width: 10rem;
        }
      }
      &-tax {
        font-size: 1.3rem;

        strong {
          font-size: 1.4rem;
        }

        span {
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media all and (min-width: 1900px) {
  .Card {
    &Body {
      &-percent {
        width: 35%;
      }

      &-price {
        font-size: 1.2em;
      }
    }

    &Footer {
      &-quota {
        figure {
          max-width: 10rem;
        }
      }

      &-tax {
        font-size: 1.5rem;

        strong {
          font-size: 1.4rem;
        }

        span {
          font-size: 1.6rem;
        }
      }
    }
  }
}
</style>
