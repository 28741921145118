import $ from 'jquery'
import Vue from 'vue'
import App from '@/App.vue'
import Carousel from '@/components/Carousel.vue'
import CardProduct from '@/components/CardProduct.vue'
import CardFeatured from '@/components/CardFeatured.vue'
import DropdownSlot from '@/components/DropdownSlot.vue'
import Banner from '@/components/Banner.vue'
import Driver from '@/components/Driver.vue'
import MenuMobile from '@/components/MenuMobile.vue'
import ProductList from '@/components/ProductList.vue'
import EventBus from '@/plugins/event-bus'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// import Http from '@/services/http'
// import NumberFormat from '@/filters/number-format'
Vue.use(EventBus)
Vue.config.productionTip = false

// Vue.use(Http)
// Vue.use(NumberFormat)
Vue.component('v-select', vSelect)
Vue.component('carousel', Carousel)
Vue.component('im-card-product', CardProduct)
Vue.component('im-card-featured', CardFeatured)
Vue.component('driver-home', Driver)
Vue.component('banner-home', Banner)
Vue.component('dropdown-slot', DropdownSlot)
Vue.component('menu-mobile', MenuMobile)
Vue.component('product-list', ProductList)

const config = {}

if (process.env.NODE_ENV !== 'production') {
  config
    .render = h => h(App)
}

$.holdReady(true)

document.addEventListener('DOMContentLoaded', () => {
  window.vm = new Vue({
    el: '#app-view',
    mounted () {
      $.holdReady(false)
    }
  })
})
