<template lang="pug">
  .modal-mask(:class='{ active: isOpen}' @click="preventDefault")
    .modal-wrapper
      .modal-container
        .modal-icon(@click="close")
          | x
        .modal-header
          slot(name='header')
        .modal-body
          slot(name='body')
        .modal-footer.d-flex.justify-content-center
          slot(name='footer')
            hr
            button.btn.btn-primary(@click="close") Aceptar
</template>

<script>
export default {
  name: 'Modal',
  props: {
    isActive: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    close () {
      this.$emit('close-modal')
    },
    preventDefault (e) {
      e.stopPropagation()
    }
  },
  watch: {
    isActive () {
      this.isOpen = this.isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
  visibility: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    visibility: visible;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  justify-content: space-around;
}

.modal-container {
  margin: 0px auto;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  width: 310px;

  i{
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
  @media all and (min-width: 360px) {
    width: 340px;
  }
  @media all and (min-width: 1024px) {
    width: 600px;
  }
}

.modal-header {
  padding: 2rem 1rem 1rem 1rem;
  margin-top: 0;
  color: #666;
  border-bottom: none;
  border: none;
}

.modal-body {
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-footer {
  border: none;
}

.modal-icon {
  display: flex;
  justify-content: flex-end;
}

</style>
