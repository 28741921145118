<template lang="pug">
  div
    custom-select(:options='installmentsList.installments' placeholder='Cuotas' label='id')
      template(slot='option' slot-scope='option')
        p {{option.description}}
          span ${{option.price}}
    //- <v-select :options="['Canada', 'United States']"></v-select>

</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      cardList: {
        'urls': {
          'register': 'https://www.google.com/',
          'delete': 'url_de_accion_para_eliminar_la_tajeta_en_decidir',
          'continue': 'https://youtube.com/'
        },
        'cards': [
          {
            'token': '538b8c44a1f056fe1972e056d9433d370d2cf15b270fde025004cda181088ff9',
            'bin': '450799',
            'last_four_digits': '4905',
            'expiration_month': '08',
            'expiration_year': '20',
            'brand': 'visa',
            'type': 'credit',
            'enabled': true,
            'holder': {
              'identification': {
                'type': 'dni',
                'number': '12345678'
              },
              'name': 'PointsCard'
            },
            'bank': {
              'name': 'ICBC'
            }
          },
          {
            'token': '538b8c44a1f056fe1972e056d9433d370d2cf15b270fde025004cda181088ff9',
            'bin': '450799',
            'last_four_digits': '4905',
            'expiration_month': '08',
            'expiration_year': '20',
            'brand': 'visa',
            'type': 'debit',
            'enabled': true,
            'holder': {
              'identification': {
                'type': 'dni',
                'number': '12345678'
              },
              'name': 'PointsCard'
            },
            'bank': {
              'name': 'ICBC'
            }
          },
          {
            'token': '538b8c44a1f056fe1972e056d9433d370d2cf15b270fde025004cda181088ff9',
            'bin': '450799',
            'last_four_digits': '4905',
            'expiration_month': '08',
            'expiration_year': '20',
            'brand': 'mastercad',
            'type': 'credit',
            'enabled': false,
            'holder': {
              'identification': {
                'type': 'dni',
                'number': '12345678'
              },
              'name': 'PointsCard'
            },
            'bank': {
              'name': 'ICBC'
            }
          },
          {
            'token': '538b8c44a1f056fe1972e056d9433d370d2cf15b270fde025004cda181088ff9',
            'bin': '450799',
            'last_four_digits': '4905',
            'expiration_month': '08',
            'expiration_year': '20',
            'brand': 'maestro',
            'type': 'debit',
            'enabled': false,
            'holder': {
              'identification': {
                'type': 'dni',
                'number': '12345678'
              },
              'name': 'PointsCard'
            },
            'bank': {
              'name': 'ICBC'
            }
          }
        ]
      },
      installmentsList: {
        'urls': {
          'api': 'https://live.decidir.com/static/v2.5/decidir.js',
          'action': 'https://localhost:6443/module/aperpago/validation',
          'cancel': 'url_para_volver_atras_tarjeta_registrada'
        },
        'card': {
          'token': '538b8c44a1f056fe1972e056d9433d370d2cf15b270fde025004cda181088ff9',
          'bin': '450799',
          'last_four_digits': '4905',
          'expiration_month': '08',
          'expiration_year': '20',
          'brand': 'visa',
          'type': 'credit',
          'enabled': true,
          'holder': {
            'identification': {
              'type': 'dni',
              'number': '12345678'
            },
            'name': 'PointsCard'
          },
          'bank': {
            'name': 'ICBC'
          }
        },
        'installments': [
          {
            'id': 1,
            'installment': 1,
            'description': '1 Cuota sin interes de ',
            'coefficient': 1,
            'price': 14.4
          },
          {
            'id': 3,
            'installment': 3,
            'description': '3 Cuotas sin interes de ',
            'coefficient': 1,
            'price': 4.8
          },
          {
            'id': 5,
            'installment': 5,
            'description': '5 Cuotas sin interes de ',
            'coefficient': 1,
            'price': 2.88
          },
          {
            'id': 9,
            'installment': 9,
            'description': '9 Cuotas fijas de ',
            'coefficient': 1.21,
            'price': 1.94
          }
        ],
        'order': {
          'total_points': 0,
          'total_cash': 14.4,
          'points': false
        },
        'payment': {
          'gateway': 'decidir',
          'public_key': '96e7f0d36a0648fb9a8dcb50ac06d260',
          'service': 'test',
          'api': 'https://developers.decidir.com/api/v2'
        }
      }

    }
  }
}
</script>

<style lang="scss" scoped>
  .container-fluid {
    // background: #dad7d1;
    background: #e9e6e0;
  }
</style>
