<template lang="pug">
  div.d-md-none.d-block
    div.triggerMenu(@click="toggleOpen" :class="{ 'cross' : isOpen }")
    span.menu
    span.menu
    span.menu
    .v-sidebar-menu(:class=" {'dropdown-is-active' : isOpen}" v-scroll-lock="isOpen")
      div.crossContainer
        span.close(@click="toggleOpen")
      slot(name="login")
      hr
      slot(name="menu")

</template>

<script>
export default {
  name: 'MenuMobile',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleOpen () {
      this.isOpen = !this.isOpen
      const body = document.body
      body.classList.toggle('overflow-hidden', this.isOpen)
    }
  },
  mounted () {
    const parent = document.querySelector('.v-sidebar-menu')
    const element = parent.querySelector('.dropdown-menu')
    if (element) {
      element.classList.remove('dropdown-menu')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "partials/mixins";

span.menu {
  display: flex;
  position: relative;
  width: 3.3rem;
  height: 4px;
  margin-bottom: 4px;
  background:#404041;
  border-radius: 3px;
  z-index: 1;
}

hr {
  border-bottom: 1px solid #CCCCCC;
  width: 95%;
  margin: 10px;
}
div.triggerMenu {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 5;

  &.cross ~ ul {
    transform: none;
  }
}

.close:before {
    content: "\f00d";
    display: block;
    font-family: "FontAwesome";
    font-size: 20px;
    width: 100%;
    text-align: center;
}
.crossContainer{
  padding: 20px 10px 20px 10px;
  display: flex;
  justify-content: flex-end;
}

.v-sidebar-menu {
  &.dropdown-is-active {
    visibility: visible;
    @include transform(translatey(0));
    @include transition(transform 0.5s 0s, visibility 0s 0s);
  }

  * {
    box-sizing: border-box;
  }
  list-style: none;
  color: #583174;
  visibility: hidden;
  background-color: #F5F6FA;
  position: fixed;
  top: 35px;
  left: 0;
  overflow: scroll;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding-bottom: 3rem;

  /* Force Hardware Acceleration */
  // @include backface-visibility(hidden);
  @include transform(translateZ(0));

  @include transform(translateY(-100%));
  @include transition(transform 0.5s 0s, visibility 0s 0.5s);

  > .vsm--list {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: .5rem 1rem;
  }

  .menu-mobile-item {
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    text-decoration: none;
    user-select: none;
    padding: .2rem 0;

    &.open {
      .submenu-wrap {
        display: block;
      }
    }
  }
  .submenu-wrap {
    display: none;
    padding-left: 20px;
  }
  .second-level-wrap {
    display: none;
  }

  .vsm--item{

    &:active {

      .vsm--title {
        a {
          color:#583174;
          font-weight: 600;
        }
      }

      .vsm--arrow {
        color: #583174;
      }
    }
  }
}
.cbp-mainlink-iicon {
  display: none;
}

</style>
