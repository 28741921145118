<template lang="pug">
  .main-container
    .preloader-wrapper
      .preloader
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="scss" scoped>
// --------------------------------------------------
// Flexbox SASS mixins
// --------------------------------------------------

// Flexbox display
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline flex display
@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($fg: 1, $fs: 0, $fb: auto) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so lets grab the
  // first item in the list and just return that.
  @if type-of($fg)=='list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box: $fg-boxflex;
  -moz-box: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Flex Flow Direction
// - applies to: flex containers
// row (default) | row-reverse | column | column-reverse
@mixin flex-direction($direction: row) {
  @if $direction==row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  }

  @else if $direction==column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  }

  @else if $direction==column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  }

  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }

  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;

  @if $value==nowrap {
    -ms-flex-wrap: none;
  }

  @else {
    -ms-flex-wrap: $value;
  }

  flex-wrap: $value;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($values: (row nowrap)) {
  // No Webkit/FF Box fallback.
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -moz-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($int: 0) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex: $int;
  flex-shrink: $int;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($value: flex-start) {
  @if $value==flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  }

  @else if $value==flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  }

  @else if $value==space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  }

  @else if $value==space-around {
    -ms-flex-pack: distribute;
  }

  @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }

  -webkit-justify-content: $value;
  justify-content: $value;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;

  @if $value==flex-start {
    -ms-flex-line-pack: start;
  }

  @else if $value==flex-end {
    -ms-flex-line-pack: end;
  }

  @else {
    -ms-flex-line-pack: $value;
  }

  align-content: $value;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($value: stretch) {
  @if $value==flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  }

  @else if $value==flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  }

  @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }

  -webkit-align-items: $value;
  align-items: $value;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;

  @if $value==flex-start {
    -ms-flex-item-align: start;
  }

  @else if $value==flex-end {
    -ms-flex-item-align: end;
  }

  @else {
    -ms-flex-item-align: $value;
  }

  align-self: $value;
}

// --------------------------------------------------
// Preloader styles
// --------------------------------------------------

$orange: #B99DCE;

.main-container {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
}

.preloader-wrapper {
  width: 100%;
  height: 100%;
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  position: relative;

  .preloader {
    display: block;
    padding: 0.85rem;
    position: relative;
    margin: 5rem;
    z-index: 889;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $orange;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: lighten($orange, 20%);
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }

    &::after {
      content: "";
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: lighten($orange, 30%);
      -webkit-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
    }
  }
}

// --------------------------------------------------
// Preloader animation
// --------------------------------------------------

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
