<template lang="pug">

  div.Driver_contenido(@click = "showModal")
    div(v-if="driver != null" :class="['Driver-container', driver.item_id]" )
      div.Driver-image
        img(loading="lazy" :src="`/img/customhome/driver_${driver.item_id}.jpg`" )
      div.Driver-text.Driver-title(v-if="driver.content_title") {{driver.content_title}}
      div.Driver-text.Driver-description(v-if="driver.content_description") {{driver.content_description}}
      div.Driver-text.Driver-columna

      //- a(v-if="driver.linkable === 1" :href="[driver.link]" :target="[driver.target]" style="width:-webkit-fill-available")
    modal(:isActive="open" @close-modal='close')
      template(slot="header")
        h4 Sacá tu tarjeta online y disfrutá nuestros beneficios!
        hr
      template(slot="body" class="allign")
        a(class='button bsj', href='//www.bancosanjuan.com/personas/tarjetas' target="_blank" ) Banco San Juan
        a(class='button bsf', href='//www.bancosantafe.com.ar/saca-tu-tarjeta?campaignId=1234&type=TC' target="_blank") Banco Santa Fe
        a(class='button ber', href='//www.bancoentrerios.com.ar/personas/tarjetas' target="_blank") Banco Entre Rios
</template>

<script>

import Modal from '@/components/Modal'

export default {
  name: 'Driver',
  props: {
    data: { type: Object }
  },
  components: {
    Modal
  },
  data () {
    return {
      driver: null,
      open: false
    }
  },
  methods: {
    parseData () {
      if (typeof this.data === 'string') {
        this.driver = JSON.parse(this.data)
      }
    },
    showModal () {
      this.open = true
    },
    close () {
      this.open = false
    }
  },
  mounted () {
    this.parseData()
  }
}
</script>
<style scoped lang="scss">

.bsj{
  background:#DA8301;
  color: whitesmoke;
  border:none;
  border-radius: 5px;
  margin: 4px;
  text-align: center;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
}

.bsf{
  background:#017A33;
  color: whitesmoke;
  border:none;
  border-radius: 5px;
  margin: 4px;
  text-align: center;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
}

.ber{
  background:#B12818;
  color: whitesmoke;
  border: none;
  border-radius: 5px;
  margin: 4px;
  text-align: center;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
}

@media (max-width: 1199px) {
  .Item-container {
    margin-bottom: 4px;
    &.Item-container-1 {
      .Driver_contenido {
        pointer-events: none;
        width: 100%;
        display: block;

      }
    }
    &.Item-container-2 {
      .Driver_contenido {
        pointer-events: none;
        width: 100%;
        display: block;

      }
    }
    &.Item-container-3 {
      .Driver_contenido {
        cursor: pointer;
        width: 100%;
        display: block;
      }
      .Driver-columna {
        display: none;
      }
    }
  }

  .Driver-container {
    display: grid;
    grid-template: 69px 180px 21px;
    column-gap: 10px;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }

  .Driver-image {
    grid-row-start: 1;
    grid-row-end: 4;
    text-align: center;
    align-content: center;
    width: 90px;
  }

  .Driver-text {
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: center;

    &.Driver-title {
      color: #FFFFFF;
      background-color: #B99DCE;
      text-transform: uppercase;
      text-align: center;
      min-width: 180px;
      height: fit-content;
    }

    &.Driver-description {
      color: #666666;
          text-align: center;
    }
  }

  .Driver-columna {
    display: none;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 100%;

  }
}

@media (min-width: 1200px) {
  .Item-container {
    margin-bottom: 4px;
    &.Item-container-1 {
      .Driver_contenido {
        pointer-events: none;
      }
    }
    &.Item-container-2 {
      .Driver_contenido {
        pointer-events: none;
      }
    }

    &.Item-container-3 {
      .Driver_contenido {
        cursor: pointer;
      }
      .Driver-columna {
        width: 0px;
        border-right: none;
      }
    }
  }

  .Driver-container {
    display: grid;
    //grid-templates-columns: 69px 170px 21px;
    column-gap: 10px;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }

  .Driver-image {
    grid-row-start: 1;
    grid-row-end: 4;
    text-align: right;
  }

  .Driver-text {
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: center;

    &.Driver-title {
      color: #FFFFFF;
      background-color: #B99DCE;
      text-transform: uppercase;
      height: fit-content;
    }
    &.Driver-description {
      color: #666666;
    }
  }

  .Driver-columna {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    border-right: 1px solid #666666;
  }
}
</style>
